import Alpine from "alpinejs";
import mask from "@alpinejs/mask";
import "htmx.org";
import { initFlowbite, Dismiss } from "flowbite";
import ApexCharts from "apexcharts";
import DateRangePicker from "flowbite-datepicker/DateRangePicker";
import Datepicker from "flowbite-datepicker/Datepicker";
import es from "flowbite-datepicker/locales/es";

// HTMX config
window.htmx = require("htmx.org");

// Giving access to the whole document to the Dismiss and init functions from Flowbite.
window.flowbite = { Dismiss, initFlowbite };

// Giving access to the whole document to Apexcharts
window.ApexCharts = ApexCharts

// Flowbite config
// Before doing anything, we need to initialize Flowbite.
initFlowbite();

// Mask plugin
Alpine.plugin(mask);

// Alpine config
window.Alpine = Alpine;

Alpine.store("darkMode", {
  // Siempre comienza en modo claro.
  darkTheme: false,
  // Esta función hace el toggle del tema.
  toggleDarkTheme() {
    this.darkTheme = !this.darkTheme;
    localStorage.setItem("color-theme", this.darkTheme ? "dark" : "light");
  },
});


// Esta store contendrá algunas funciones que pueden resultar útiles en la interfaz.
Alpine.store("utilidades", {
  // El unico proposito de esta funcion es para que el texto seleccionado se vea camelizado.
  // De esta forma, será más fácil manipularlo en el JS.
  camelize(str) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  },
});

// NOTE: Las siguientes dos stores (graficaAltaVacantes y graficaAltaPostulaciones) podrían
// ser combinadas en una sola, debido a que comparten muchas similitudes. Por ahora, están
// separadas para mantener la modularidad. En un futuro, habría que hacer la evaluación sobre
// si vale la pena combinarlas o no.
// Esta store de acá es para la gráfica de barras de alta de vacantes por semana.
Alpine.store("graficaAltaVacantes", {
  // Aquí se almacena el total de vacantes en el rango de tiempo. Nos servirá para
  // mostrarlo en la interfaz.
  totalVacantes: "0",

  // Aquí se almacenará un pointer a la instancia de la gráfica. Nos servirá para
  // poder destruirla y/o actualizarla.
  altaVacantesChart: null,

  // Este rango de tiempo será útil para mostrarlo en la interfaz, y se adaptará según
  // la selección del usuario.
  rangoTiempo: "-",

  // Configuraciones generales de la gráfica.
  opciones: {
    colors: ["#9061F9"],
    series: [
      {
        name: "Vacantes",
        data: [],
      },
    ],
    chart: {
      type: "bar",
      height: "320px",
      fontFamily: "Inter, sans-serif",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "70%",
        borderRadiusApplication: "end",
        borderRadius: 8,
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      style: {
        fontFamily: "Inter, sans-serif",
      },
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 1,
        },
      },
    },
    stroke: {
      show: true,
      width: 0,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      strokeDashArray: 4,
      padding: {
        left: 2,
        right: 2,
        top: -14,
      },
    },
    // Esto es lo que permite ver los valores sobre las barras.
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: false,
    },
    xaxis: {
      floating: false,
      labels: {
        show: true,
        style: {
          fontFamily: "Inter, sans-serif",
          cssClass: "text-xs font-normal fill-gray-500 dark:fill-gray-400",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
  },

  // Esta es una función reutilizable que nos permite obtener los datos necesarios para
  // generar la gráfica. Por defecto, se obtienen los datos de la semana actual.
  async getVacantesCreadas(
    rango = "semanaActual",
    fechaInicio = "",
    fechaFin = "",
  ) {
    // Hay que obtener todos los datos de las vacantes para la semana actual.
    const datosVacantes = await fetch(
      `/api-interna/vacantes/?rango=${rango}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`,
    );
    const datosVacantesJson = await datosVacantes.json();

    // Hay que obtener tanto las fechas (eje x) como el número de vacantes creadas (eje y).
    const labels = Object.keys(datosVacantesJson?.fechas);
    const countVacantes = Object.values(datosVacantesJson?.fechas);
    const totalVacantes = datosVacantesJson?.total;
    const rangoTiempo = datosVacantesJson?.rangoTiempo;

    // El total de vacantes se almacena en la store.
    this.totalVacantes = totalVacantes;

    // El rango de tiempo se almacena en la store.
    this.rangoTiempo = rangoTiempo;

    // Hay que crear el objeto data final, el cual será alimentado a apexcharts.
    const data = [];
    labels.forEach((label, index) => {
      data.push({ x: label, y: countVacantes[index] });
    });
    return data;
  },

  // Método para crear la gráfica.
  async createChartAltaVacantes() {
    // Se obtienen los datos necesarios para la gráfica.
    const data = await this.getVacantesCreadas();
    // Se actualizan los datos en las opciones.
    this.opciones.series[0].data = data;
    // Solo se crea la gráfica si se pudo seleccionar el elemento del DOM, y si
    // ApexCharts está definido.
    if (
      document.getElementById("graficaAltaVacantes") &&
      typeof ApexCharts !== "undefined"
    ) {
      this.altaVacantesChart = new ApexCharts(
        document.getElementById("graficaAltaVacantes"),
        this.opciones,
      );
      this.altaVacantesChart.render();
    }
  },

  // Método para actualizar la gráfica.
  async updateChartAltaVacantes(rango, fechaInicio, fechaFin) {
    // console.log(rango);
    // Se obtienen los datos necesarios para la gráfica.
    const data = await this.getVacantesCreadas(rango, fechaInicio, fechaFin);
    // Se actualizan las opcinones de la gráfica.
    this.altaVacantesChart.updateOptions({
      series: [
        {
          data,
        },
      ],
    });
  },
});

// Esta store es para la gráfica de barras de postulaciones por semana.
Alpine.store("graficaAltaPostulaciones", {
  // Aquí se almacena el total de vacantes en el rango de tiempo. Nos servirá para
  // mostrarlo en la interfaz.
  totalPostulaciones: "0",

  // Aquí se almacenará un pointer a la instancia de la gráfica. Nos servirá para
  // poder destruirla y/o actualizarla.
  altaPostulacionesChart: null,

  // Este rango de tiempo será útil para mostrarlo en la interfaz, y se adaptará según
  // la selección del usuario.
  rangoTiempo: "-",

  // Configuraciones generales de la gráfica.
  opciones: {
    colors: ["#56D3A3"],
    series: [
      {
        name: "Vacantes",
        data: [],
      },
    ],
    chart: {
      type: "bar",
      height: "320px",
      fontFamily: "Inter, sans-serif",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "70%",
        borderRadiusApplication: "end",
        borderRadius: 8,
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      style: {
        fontFamily: "Inter, sans-serif",
      },
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 1,
        },
      },
    },
    stroke: {
      show: true,
      width: 0,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      strokeDashArray: 4,
      padding: {
        left: 2,
        right: 2,
        top: -14,
      },
    },
    // Esto es lo que permite ver los valores sobre las barras.
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: false,
    },
    xaxis: {
      floating: false,
      labels: {
        show: true,
        style: {
          fontFamily: "Inter, sans-serif",
          cssClass: "text-xs font-normal fill-gray-500 dark:fill-gray-400",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
  },

  // Esta es una función reutilizable que nos permite obtener los datos necesarios para
  // generar la gráfica. Por defecto, se obtienen los datos de la semana actual.
  async getPostulacionesCreadas(
    rango = "semanaActual",
    fechaInicio = "",
    fechaFin = "",
  ) {
    // Hay que obtener todos los datos de las vacantes para la semana actual.
    const datosPostulaciones = await fetch(
      `/api-interna/postulaciones/?rango=${rango}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`,
    );
    const datosPostulacionesJson = await datosPostulaciones.json();

    // Hay que obtener tanto las fechas (eje x) como el número de vacantes creadas (eje y).
    const labels = Object.keys(datosPostulacionesJson?.fechas);
    const countPostulaciones = Object.values(datosPostulacionesJson?.fechas);
    const totalPostulaciones = datosPostulacionesJson?.total;
    const rangoTiempo = datosPostulacionesJson?.rangoTiempo;

    // El total de vacantes se almacena en la store.
    this.totalPostulaciones = totalPostulaciones;

    // El rango de tiempo se almacena en la store.
    this.rangoTiempo = rangoTiempo;

    // Hay que crear el objeto data final, el cual será alimentado a apexcharts.
    const data = [];
    labels.forEach((label, index) => {
      data.push({ x: label, y: countPostulaciones[index] });
    });
    return data;
  },

  // Método para crear la gráfica.
  async createChartAltaPostulaciones() {
    // Se obtienen los datos necesarios para la gráfica.
    const data = await this.getPostulacionesCreadas();
    // Se actualizan los datos en las opciones.
    this.opciones.series[0].data = data;
    // Solo se crea la gráfica si se pudo seleccionar el elemento del DOM, y si
    // ApexCharts está definido.
    if (
      document.getElementById("graficaAltaPostulaciones") &&
      typeof ApexCharts !== "undefined"
    ) {
      this.altaPostulacionesChart = new ApexCharts(
        document.getElementById("graficaAltaPostulaciones"),
        this.opciones,
      );
      this.altaPostulacionesChart.render();
    }
  },

  // Método para actualizar la gráfica.
  async updateChartAltaPostulaciones(rango, fechaInicio, fechaFin) {
    // console.log(rango);
    // Se obtienen los datos necesarios para la gráfica.
    const data = await this.getPostulacionesCreadas(
      rango,
      fechaInicio,
      fechaFin,
    );
    // Se actualizan las opcinones de la gráfica.
    this.altaPostulacionesChart.updateOptions({
      series: [
        {
          data,
        },
      ],
    });
  },
});

// Esta store es para la gráfica de pastel de vacantes activas por organizacion/cliente.
Alpine.store("graficaVacantesActivasClientes", {
  // Aquí se almacena el total de vacantes en el rango de tiempo. Nos servirá para
  // mostrarlo en la interfaz.
  totalVacantesActivas: "0",

  // Aquí se almacenará un pointer a la instancia de la gráfica. Nos servirá para
  // poder destruirla y/o actualizarla.
  vacantesActivasClienteChart: null,

  // Configuraciones generales de la gráfica.
  opciones: {
    series: [
      {
        name: "Vacantes activas",
        color: "#ec4899",
        data: [],
      },
    ],
    chart: {
      sparkline: {
        enabled: false,
      },
      type: "bar",
      width: "100%",
      height: 400,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: "100%",
        borderRadiusApplication: "end",
        borderRadius: 6,
        dataLabels: {
          position: "top",
        },
      },
    },
    legend: {
      show: false,
      position: "bottom",
    },
    // Esto es lo que permite ver los valores sobre las barras.
    dataLabels: {
      enabled: true,
      textAnchor: "middle",
      style: {
        fontSize: "12px",
        fontFamily: "Inter, sans-serif",
        colors: [],
      },
      formatter: function (val, opts) {
        // Accessing category for the current data point
        const category = opts.w.config.xaxis.categories[opts.dataPointIndex];
        return category + ": " + val;
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      formatter: function (value) {
        return value;
      },
    },
    xaxis: {
      labels: {
        show: true,
        style: {
          fontFamily: "Inter, sans-serif",
          cssClass: "text-xs font-normal fill-gray-500 dark:fill-gray-400",
        },
      },
      formatter: function (value) {
        return value;
      },
      categories: [],
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
        style: {
          fontFamily: "Inter, sans-serif",
          cssClass: "text-xs mr-4 font-normal fill-gray-500 dark:fill-gray-400",
        },
      },
    },
    grid: {
      show: true,
      strokeDashArray: 4,
      padding: {
        left: 2,
        right: 2,
        top: -20,
      },
    },
    fill: {
      opacity: 1,
    },
  },

  // Este método es el que nos permite obtener, para la vacante seleccionada, los datos
  // necesarios para generar la gráfica.
  async getVacantesActivasClientes() {
    // Hay que obtener tanto los series como los labels de la gráfica, según la
    // vacante seleccionada.
    const datosVacantesActivasClientes = await fetch(
      `/api-interna/organizaciones/vacantes/`,
    );
    const datosVacantesActivasClientesJson =
      await datosVacantesActivasClientes.json();

    // Hay que obtener tanto el total de vacantes, como los nombres de las organizaciones (eje y)
    // y su respectivo número de vacantes activas (eje x).
    const labels = Object.keys(
      datosVacantesActivasClientesJson?.organizaciones,
    );
    const countVacantesActivas = Object.values(
      datosVacantesActivasClientesJson?.organizaciones,
    );
    const totalVacantesActivas = datosVacantesActivasClientesJson?.total;

    // El total de vacantes activas se almacena en la store.
    this.totalVacantesActivas = totalVacantesActivas;

    // Hay que crear el objeto data final, el cual será alimentado a apexcharts.
    return { labels, countVacantesActivas };
  },

  // Método para crear la gráfica.
  async createChartVacantesActivasClientes() {
    // Antes que nada, se verifica si estamos en dark mode o light mode, y con base en eso
    // se cambian los colores de los datalabels.
    this.updateDataLabels(document.documentElement.classList.contains("dark"));
    // Se obtienen los datos necesarios para la gráfica.
    const { labels, countVacantesActivas } =
      await this.getVacantesActivasClientes();

    // Se actualizan los datos en las opciones.
    this.opciones.series[0].data = countVacantesActivas;
    this.opciones.xaxis.categories = labels;
    // Solo se crea la gráfica si se pudo seleccionar el elemento del DOM, y si
    // ApexCharts está definido.
    if (
      document.getElementById("graficaVacantesActivasClientes") &&
      typeof ApexCharts !== "undefined"
    ) {
      this.vacantesActivasClienteChart = new ApexCharts(
        document.getElementById("graficaVacantesActivasClientes"),
        this.opciones,
      );
      this.vacantesActivasClienteChart.render();
    }
  },

  // Este método está aqui para actualizar los datalabels cuando cambia el tema.
  updateDataLabels(isDarkMode) {
    // Si es dark mode, se cambia el color de los datalabels a blanco. Caso contario,
    // se cambian a negro.
    if (isDarkMode) {
      this.opciones.dataLabels.style.colors = ["#fff"];
    } else {
      this.opciones.dataLabels.style.colors = ["#000"];
    }
    // Solo si la gráfica existe, se actualiza.
    if (this.vacantesActivasClienteChart) {
      // console.log("Existia, por lo que se actualiza");
      this.vacantesActivasClienteChart.updateOptions(this.opciones);
    }
  },
});

// Esta store es para poder acceder a la vacante seleccionada al generar la gráfica
// para las métricas de la vacante. Tiene que estar aquí porque se necesita tanto en el
// body como en los scripts. Otra opción habría sido agregarla en el elemento <html>, pero
// decidí ponerlo aquí para experimentar con las stores de Alpine.
Alpine.store("metricasVacante", {
  // Este valor permitirá identificar a la vacante seleccionada.
  vacanteId: "",

  // Este valor guarda la instancia actual de la gráfica. Nos servirá para poder
  // destruirla y crear una nueva cada vez que se seleccione una vacante.
  metricasVacanteChart: null,

  // Configuraciones generales de la gráfica.
  opciones: {
    series: [],
    colors: [],
    labels: [],
    chart: {
      height: 420,
      width: "100%",
      type: "pie",
    },
    stroke: {
      colors: ["white"],
      lineCap: "",
    },
    plotOptions: {
      pie: {
        labels: {
          show: true,
        },
        size: "100%",
        dataLabels: {
          offset: -25,
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontFamily: "Inter, sans-serif",
      },
      formatter: function (val, opts) {
        // Se formatea el valor para mostrar tanto el porcentaje como el número de candidatos.
        return `${Math.round(val)}% (${opts.w.globals.series[opts.seriesIndex]} candidato/s)`;
      },
    },
    legend: {
      position: "bottom",
      fontFamily: "Inter, sans-serif",
      labels: {
        useSeriesColors: true,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value + " candidato/s";
        },
      },
    },
    xaxis: {
      labels: {
        formatter: function (value) {
          return value + " candidato/s";
        },
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
  },

  // Este método es el que nos permite obtener, para la vacante seleccionada, los datos
  // necesarios para generar la gráfica.
  async getMetricasVacanteSeleccionada() {
    // Hay que obtener tanto los series como los labels de la gráfica, según la
    // vacante seleccionada.
    const datosVacante = await fetch(
      `/api-interna/vacantes/metricas/${this.vacanteId}/`,
    );
    const datosVacanteJson = await datosVacante.json();

    // Hay que crear los arrays de series y labels.
    const labels = Object.keys(datosVacanteJson?.estados);
    const series = Object.values(datosVacanteJson?.estados);
    const colors = datosVacanteJson?.colores;
    return { labels, series, colors };
  },

  // Esta función se encarga de la creación del chart.
  async createChartMetricasVacante() {
    // Obteniendo tanto las series como los labels de la vacante seleccionada.
    const { labels, series, colors } =
      await this.getMetricasVacanteSeleccionada();

    // Hay que volver a activar el botón de "Generar gráfica" para que el usuario
    // pueda generar otra gráfica.
    document.getElementById("btnGenerarGrafica").disabled = false;

    // La gráfica no se puede crear si no hay datos. Entonces, hay que mostrar un mensaje
    // al usuario en este caso, en vez de la gráfica.
    if (!labels.length || !series.length || !colors.length) {
      // Primero, hay que verificar si existe una gráfica previa. En caso de que si. hay que borrarla.
      if (this.metricasVacanteChart) {
        this.deleteChartMetricasVacante();
      }

      // Si no hay datos, se muestra al usuario un mensaje en vez de la gráfica.
      document.getElementById("metricas-vacante-pie-chart").innerHTML =
        `<div class="rounded-lg border-2 border-dashed border-red-200 p-4 dark:border-red-500">
            <h2 class="text-center italic text-red-500">La vacante seleccionada no tiene postulaciones</h2>
          </div>
        `;
      return;
    }

    // Si hay datos, hay que verificar si el elemento target existe y si ApexCharts
    // está definido.
    if (
      document.getElementById("metricas-vacante-pie-chart") &&
      typeof ApexCharts !== "undefined"
    ) {
      // En caso de que ya exista un chart, no hay que crearlo, sino
      // actualizarlo.
      if (this.metricasVacanteChart) {
        // console.log("Actualizando chart");
        this.metricasVacanteChart.updateOptions({
          labels,
          series,
          colors,
        });
        return;
      }
      // Caso contario, creamos el chart.
      // console.log("Creando chart");
      // Primero, hay que actualizar las series, labels y colores en las opciones.
      this.opciones.series = series;
      this.opciones.labels = labels;
      this.opciones.colors = colors;
      // Primero, hay que borrar el placeholder.
      document.getElementById("metricas-vacante-pie-chart").innerHTML = "";
      this.metricasVacanteChart = new ApexCharts(
        document.getElementById("metricas-vacante-pie-chart"),
        this.opciones,
      );
      this.metricasVacanteChart.render();
    }
  },

  // Esta función se encarga de borrar el chart.
  deleteChartMetricasVacante() {
    // console.log("Borrando chart");
    // Se destruye la instancia del chart, solo si existe.
    if (this.metricasVacanteChart) {
      this.metricasVacanteChart.destroy();
      // La vacante seleccionada se resetea.
      this.vacanteId = "";
      // Esto es necesario para que el chart se vuelva a crear la próxima vez
      // que se haga el dispatch.
      this.metricasVacanteChart = null;
    }
  },
});

Alpine.start();

// Datepicker config
// Para que el datepicker range esté en español, hay que importar el locale de español.
Datepicker.locales.es = es.es;
// Tan solo el contenido del DOM esté cargado, se inicializan los datepickers.
document.addEventListener("DOMContentLoaded", () => {
  // Por cada elemento con el atributo "datepickerrange", se inicializa un datepicker range custom.
  document.querySelectorAll("[datepickerrange]").forEach((el) => {
    const dr = new DateRangePicker(el);
    dr.setOptions({ language: "es", format: "yyyy-mm-dd" });
  });
  // Por cada elemento con el atributo "datepicker", se inicializa un datepicker custom.
  document.querySelectorAll("[datepicker]").forEach((el) => {
    const d = new Datepicker(el);
    d.setOptions({ language: "es", format: "yyyy-mm-dd" });
  });
});

// Cuando se generan los campos de forma dinámica en la alta de candidatos, hay que inicializar
// el datepicker en estos campos también.
document.addEventListener("campo-candidato-agregado", (event) => {
  // Si el evento fue causado por el botón de agregar documento, se seleccionan los campos
  // respectivos. Si por el contrario, fue causado por el botón de agregar referencia,
  // se seleccionan los campos respectivos.
  const campo =
    event.target.id === "agregarDocumento"
      ? document.querySelector(
          '[x-ref="contenedorDocumentos"] > div:last-child',
        )
      : document.querySelector(
          '[x-ref="contenedorReferencias"] > div:last-child',
        );

  // Por cada elemento con el atributo "datepicker", se inicializa un datepicker custom.
  campo.querySelectorAll("[datepicker]").forEach((el) => {
    const d = new Datepicker(el);
    d.setOptions({ language: "es", format: "yyyy-mm-dd" });
  });
});
